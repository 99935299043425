import { ErrorObject } from '@commercetools/platform-sdk'
import { useQuery, UseQueryResult } from 'react-query'

import { useBusinessUnit } from 'commercetools/hooks/use-business-unit'
import { getExternalEquipment } from 'external/api/external-equipment'
import { ExternalEquipment } from 'external/types'

export function useExternalEquipment(): UseQueryResult<ExternalEquipment[], ErrorObject> {
  const businessUnit = useBusinessUnit()

  return useQuery(['getExternalEquipment', businessUnit.erpAccountNumber], getExternalEquipment, {
    enabled: Boolean(businessUnit.erpAccountNumber),
    onError: console.error,
    retry: false,
  })
}
