import {
  Button,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Skeleton,
  Stack,
  SystemStyleInterpolation,
  Text,
} from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { useMemo } from 'react'

import { CheckIcon } from 'components/elements/Icons/CheckIcon'
import { ChevronRightIcon } from 'components/elements/Icons/ChevronRightIcon'
import { ExternalEquipment } from 'external/types'
import { getExternalEquipmentDisplayName } from 'external/utils'
import { arrayHasValue } from 'utils/arrayHasValue'

type Props = {
  equipments: ReadonlyArray<ExternalEquipment> | undefined
  isLoading: boolean
  selectedEquipmentId: string | undefined
  updateSelectedEquipment: (equipment: ExternalEquipment | undefined) => void
}

const styles = {
  menuButton: {
    marginLeft: 0.5,
    borderRadius: 'base',
    height: '44px',
    paddingStart: 2,
    paddingEnd: 2,
    maxW: { base: 32, md: 60 },
    _hover: {},
    _focusVisible: {},
  },
  searchIn: {
    textAlign: 'start',
    fontSize: '2xs',
  },
  allEquipmentWrapper: {
    gap: 1,
    align: 'center',
  },
  allEquipment: {
    textAlign: 'start',
    fontSize: '2xs',
    fontWeight: 'normal',
  },
  icon: {
    boxSize: 2,
    transform: 'rotate(90deg)',
    color: 'inherit',
  },
  menuList: {
    p: 0,
    zIndex: '1000',
  },
  menuStack: {
    spacing: '0',
    direction: 'column',
    overflow: 'auto',
    maxH: 'calc(0.75 * var(--chakra-vh))',
  },
  menuItem: {
    py: 4,
    _hover: { bg: 'primary.grey.100' },
    _focusVisible: { bg: 'primary.grey.100' },
    paddingLeft: 10,
    _selected: {
      paddingLeft: 3,
      bg: 'secondary.blue',
    },
  },
} as const satisfies SystemStyleInterpolation

export const QuickSearchEquipmentMenu = ({
  equipments,
  isLoading,
  selectedEquipmentId,
  updateSelectedEquipment,
}: Props) => {
  const { t } = useTranslation('common')

  const selectedEquipment = useMemo(
    () => equipments?.find((equipment: ExternalEquipment) => equipment.serialNumber === selectedEquipmentId),
    [equipments, selectedEquipmentId],
  )

  return (
    <Menu>
      <MenuButton
        as={Button}
        {...styles.menuButton}
        bgColor={selectedEquipment ? 'secondary.blue' : 'primary.grey.100'}
        color={selectedEquipment ? 'primary.blue' : 'primary.black'}
      >
        <Text {...styles.searchIn}>{t('search-in')}</Text>
        <Flex {...styles.allEquipmentWrapper}>
          <Text {...styles.allEquipment} isTruncated>
            {selectedEquipment ? getExternalEquipmentDisplayName(selectedEquipment) : t('all-equipment')}
          </Text>
          <ChevronRightIcon {...styles.icon} />
        </Flex>
      </MenuButton>

      <Portal>
        <MenuList {...styles.menuList}>
          {isLoading ? (
            <MenuItem {...styles.menuItem} pl={3}>
              <Skeleton width="full" h={6} />
            </MenuItem>
          ) : arrayHasValue(equipments) ? (
            <Stack divider={<Divider />} {...styles.menuStack}>
              <MenuItem
                {...styles.menuItem}
                aria-selected={!selectedEquipment}
                icon={!selectedEquipment ? <CheckIcon boxSize={4} /> : undefined}
                onClick={() => updateSelectedEquipment(undefined)}
              >
                {t('all-equipment')}
              </MenuItem>
              {equipments.map((equipment) => {
                const isSelected = equipment.serialNumber === selectedEquipment?.serialNumber
                return (
                  <MenuItem
                    key={equipment.serialNumber}
                    {...styles.menuItem}
                    aria-selected={isSelected}
                    icon={isSelected ? <CheckIcon boxSize={4} /> : undefined}
                    onClick={() => updateSelectedEquipment(equipment)}
                  >
                    {getExternalEquipmentDisplayName(equipment)}
                  </MenuItem>
                )
              })}
            </Stack>
          ) : (
            <MenuItem {...styles.menuItem}>{t('no-equipment-available')}</MenuItem>
          )}
        </MenuList>
      </Portal>
    </Menu>
  )
}
