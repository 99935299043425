import type { QueryFunctionContext } from 'react-query'

import client from 'external/client'
import { ExternalEquipment } from 'external/types'

type ArgumentsEquipment = QueryFunctionContext<[queryFnName: 'getExternalEquipment', companyNumber: string | undefined]>

export function getExternalEquipment({
  signal,
  queryKey: [, companyNumber],
}: ArgumentsEquipment): Promise<ExternalEquipment[]> {
  return client<ExternalEquipment[]>(`/companies/${companyNumber}/equipments`, { signal })
}
