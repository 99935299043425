import { ABORT_ERROR_NAME } from 'commercetools/client'
import { appInsights } from 'logging/appInsight'

function client<TData>(url: string, init?: RequestInit): Promise<TData> {
  return new Promise(async (resolve, reject) => {
    return fetch('/api/external' + url, {
      ...init,
      headers: {
        'Content-Type': 'application/json',
        ...init?.headers,
      },
    })
      .then(async (response) => {
        try {
          const data = await response.json()
          return response.ok ? resolve(data) : reject(data)
        } catch (error) {
          reject({ error })
        }
      })
      .catch((error) => {
        if (error.name !== ABORT_ERROR_NAME) {
          console.error(error)
          appInsights.trackException({ exception: new Error(error) })
        }
        reject({ error })
      })
  })
}

export default client
